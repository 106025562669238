<template>
    <div class="main-bg">
        <div v-show="loading" id="semiTransparenDiv"></div>
        <div class="main-bg-wrapper">
            <div class="logo">
                <img :src="require('@/assets/images/logo-img.png')" alt="imageName">
                <img :src="require('@/assets/images/logo-name.png')">
            </div>
            <div class="form-table">
                <div class="form-inner">
                    <p>Reset your Password</p>
                </div>
                <div class="form">
                    <div class="form-group">
                        <label for="exampleInputEmail1">New Password</label>
                        <input :type="newPwdFieldType" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Password" v-model="new_pwd">
                        <i :class="['fa ', newPwdFieldType === 'password' ? 'fa-eye-slash' : 'fa-eye']" aria-hidden="true" @click="switchnewPwd()"></i>
                        <span v-if="new_pwd_error" class="error_alert">{{new_pwd_error_message}}</span>
                    </div>

                    <div class="form-group">
                        <label for="exampleInputEmail1">Confirm Password</label>
                        <input :type="cnfrmPwdFieldType" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Password" v-model="confirm_pwd">
                        <i :class="['fa ', cnfrmPwdFieldType === 'password' ? 'fa-eye-slash' : 'fa-eye']" aria-hidden="true" @click="switchcnfrmPwd()"></i>
                        <span v-if="cnfrm_pwd_error" class="error_alert">{{cmfrm_pwd_error_message}}</span>
                    </div>


                </div>
                <div class="button">
                    <button @click="ResetPassword()">RESET PASSWORD</button>
                </div>
                <div class="back-to-login">
                    <router-link to="/" replace>BACK TO LOGIN</router-link>
                </div>
            </div>
        </div>
    </div>
  
</template>

<script>
    const axios = require('axios').default;
    const baseUrl =  process.env.VUE_APP_BASE_URL || 'http://192.168.0.159:8080'

    export default {
        name: 'resetPassword',
        data () {
            return {
                password:'',
                loading: false,
                errors: [],
                token: this.$route.query.token,
                new_pwd:'',
                confirm_pwd:'',
                newPwdFieldType:"password",
                cnfrmPwdFieldType:"password",
                new_pwd_error:false,
                cnfrm_pwd_error:false,
                new_pwd_error_message:"",
                cmfrm_pwd_error_message:"",
            }
            },
        methods: {

            switchnewPwd(){
                this.newPwdFieldType = this.newPwdFieldType === "password" ? "text":"password";
            },
            switchcnfrmPwd(){
                this.cnfrmPwdFieldType = this.cnfrmPwdFieldType === "password" ? "text":"password";
            },

            async ResetPassword(){
                this.errors=[]
                if (this.validate()) {
                    this.loading= true;
                    try{
                        const data =  { "new_password": this.new_pwd };
                          const headers = { 
                            "Content-Type": "application/json",
                            "Authorization":'Bearer '+this.token
                        };
                        const response =await  axios.put(`${baseUrl}/auth/reset_password`, data,{headers})
                        this.loading = false;
                        // console.log(response);
                        await this.$toasted.success(response.data['detail']);
                        await this.$router.push('/')
                    }
                    catch (e) {
                        this.loading = false;
                        var i;
                        for (i = 0; i < e.response.data.errors.length; ++i) {
                            this.$toasted.error(e.response.data.errors[i].message)
                        }
                    }

                }

            },

            validate(){
                this.new_pwd_error=false
                this.cnfrm_pwd_error=false
                console.log('validating')
                if ((!this.new_pwd) || (this.new_pwd.trim().length<=0)) {
                    this.new_pwd_error=true
                    this.new_pwd_error_message="Please provide new password!"
                    this.errors.push("Please provide new password!")
                    this.$toasted.error('Please provide new password!');
                }

                if ((!this.confirm_pwd) || (this.confirm_pwd.trim().length<=0)) {
                    this.cnfrm_pwd_error=true
                    this.cmfrm_pwd_error_message="Please provide confirm password!"
                    this.errors.push("Please provide confirm password!")
                    this.$toasted.error('Please provide confirm password!');
                }


                if ((this.confirm_pwd.trim().length) && (this.confirm_pwd.trim().length<8) ){
                    this.cnfrm_pwd_error=true
                    this.cmfrm_pwd_error_message="Your password must contain at least 8 characters!"
                    this.errors.push("Your password must contain at least 8 characters!")
                    this.$toasted.error('Your password must contain at least 8 characters.!');
                }

                if ((this.new_pwd.trim().length) && (this.new_pwd.trim().length<8) ){
                    this.new_pwd_error=true
                    this.new_pwd_error_message="Your password must contain at least 8 characters!"
                    this.errors.push("Your password must contain at least 8 characters!")
                    this.$toasted.error('Your password must contain at least 8 characters.!');
                }

                if (Number(this.new_pwd)) {
                    this.new_pwd_error=true
                    this.new_pwd_error_message="Your password can't be entirely numeric!"
                    this.errors.push("Your password can't be entirely numeric.")
                    this.$toasted.error('Your password cannot be entirely numeric.');
                }

                if (Number(this.confirm_pwd)) {
                    this.cnfrm_pwd_error=true
                    this.cmfrm_pwd_error_message="Your password can't be entirely numeric!"
                    this.errors.push("Your password can't be entirely numeric.")
                    this.$toasted.error('Your password cannot be entirely numeric.');
                }

                if (this.new_pwd != this.confirm_pwd){
                    this.errors.push("New and Confirm password must be identical!")
                    this.$toasted.error('New and Confirm password must be identical!');
                }



                if (!this.errors.length) {
                    return true;
                } 
            },

            
        },
        mounted(){

        },
        created(){

        },
       
    }

</script>


<style>

</style>